import type { AfterViewInit, TrackByFunction } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  debounceTime,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import type { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import type { MatCheckboxChange } from '@angular/material/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MapPipe } from '../../pipes/map.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type returnType = string;

type IReturnType = string[];

export interface GroupSelectItemValue {
  id: string;
  label: string;
  parentIds?: string[];
}

export type GroupSelectConfig = {
  parents: GroupSelectItemValue[];
  children: GroupSelectItemValue[];
};

type checkboxCtrl = FormControl<boolean>;

interface iCheckboxValues {
  [attributeId: returnType]: boolean;
}

interface iCheckboxFormcontrols {
  [attributeId: returnType]: checkboxCtrl;
}

interface iItem {
  label: string;
  toggle$: BehaviorSubject<boolean>; // collapsable
  childrenCtrls?: checkboxCtrl[];
  ctrl?: checkboxCtrl;
}
interface toggleStates {
  [key: string]: BehaviorSubject<boolean>;
}

@Component({
  selector: 'ui-group-select',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    ScrollingModule,
    MapPipe,
  ],
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupSelectComponent),
      multi: true,
    },
  ],
})
export class GroupSelectComponent
  extends ControlValueAccessorDirective<IReturnType>
  implements AfterViewInit
{
  private _viewFullInit$ = new BehaviorSubject<boolean>(false);
  ngAfterViewInit() {
    this._viewFullInit$.next(true);
  }

  tmpOpenStatus: toggleStates = {};

  private _config$ = new ReplaySubject<GroupSelectConfig>();

  @Input() set config(config: GroupSelectConfig | null) {
    if (config) {
      this._config$.next(config);
    }
  }

  parents$ = this._config$.pipe(map(config => config.parents));
  children$ = this._config$.pipe(map(config => config.children));

  _fb = new FormBuilder().nonNullable;
  searchQuery = this._fb.control<string>('');
  searchQuery$ = new BehaviorSubject<string>('');
  searchQueryResettable$: Observable<boolean> = this.searchQuery$.pipe(map(sq => !!sq));
  showOnlyChecked$ = new BehaviorSubject<boolean>(false);
  toggleAll$ = new BehaviorSubject<boolean>(false);
  resetValues$ = new BehaviorSubject(true);

  private _valueCtrls$ = combineLatest([
    this._config$,
    this.resetValues$,
    this._viewFullInit$,
  ]).pipe(
    map(([config]) => {
      return config.children.reduce((acc: iCheckboxFormcontrols, curr: GroupSelectItemValue) => {
        acc[curr.id] = new FormBuilder().nonNullable.control<boolean>(
          (this.control?.value ?? []).includes(curr.id)
        );
        return acc;
      }, {});
    }),
    shareReplay(1)
  );

  valueFormGroup$ = this._valueCtrls$
    .pipe(
      map(controls => {
        return new FormGroup(controls);
      })
    )
    .pipe(shareReplay(1));

  toggle$: Observable<toggleStates> = this.parents$.pipe(
    map(parents => {
      parents.forEach(parent => {
        /**
         * Handle open / close state
         * Persist open status by tarifbereich
         */
        this.tmpOpenStatus[parent.id] = this.tmpOpenStatus[parent.id] ?? new BehaviorSubject(false);
      });

      return this.tmpOpenStatus;
    })
  );

  items$: Observable<Array<iItem>> = combineLatest([
    this.parents$,
    this.children$,
    this.valueFormGroup$,
    this.showOnlyChecked$,
    this.searchQuery$,
    this.toggle$,
    this.toggle$.pipe(switchMap(toggles => combineLatest(Object.values(toggles)))), // refresh on toggle changes
  ]).pipe(
    map(([parents, children, valueFormGroup, onlyChecked, searchQuery, toggles, toggleChange]) => {
      const flatItems: Array<iItem> = [];
      const loweredSearchQuery = searchQuery?.toLowerCase() || '';

      const isQueryMatched = (label: string) => label.toLowerCase().includes(loweredSearchQuery);

      const filteredChildren = children.filter(child => isQueryMatched(child.label));

      const filteredParents = parents.filter(parent =>
        filteredChildren.some(child => child.parentIds?.includes(parent.id))
      );

      filteredParents.forEach(parent => {
        const parentChildren = filteredChildren.filter(child =>
          child.parentIds?.includes(parent.id)
        );
        const childrenCtrls = parentChildren.map(attr => valueFormGroup.controls[attr.id]);
        const childrenCtrlsValues = childrenCtrls.map(ctrl => ctrl.value);

        // show only if one child is selected if onlycChecked is active
        if (onlyChecked && !this.checkOneTrue(childrenCtrlsValues)) {
          return;
        }

        const toggle$ = onlyChecked // force open items of onlyChecked is active
          ? new BehaviorSubject(true)
          : toggles[parent.id];

        // parent ------------------
        flatItems.push({
          childrenCtrls,
          label: parent.label,
          toggle$,
        } satisfies iItem);

        /**
         * if parent is close, do not add childs
         */
        if (!toggle$.value) {
          return;
        }

        // childs ------------------
        parentChildren.forEach(child => {
          const ctrl = valueFormGroup.controls[child.id];
          const visible = onlyChecked ? ctrl.value : true;

          /**
           * do not add item if visible is false and
           * Disable checkbox to prevent mark it
           */
          if (visible) {
            ctrl.enable();
          } else {
            ctrl.disable();
            return;
          }

          flatItems.push({
            ctrl,
            label: child.label || '',
            toggle$,
          } satisfies iItem);
        });
      });

      return flatItems;
    }),
    shareReplay(1)
  );

  /**
   * Count length of displayed items
   */
  showItemsCount$ = this.items$.pipe(map(items => items.length));

  constructor() {
    super();

    this.searchQuery.valueChanges
      .pipe(takeUntilDestroyed(), debounceTime(250))
      .subscribe(searchQuery => this.searchQuery$.next(searchQuery));

    /**
     * Watch for formgroup value change
     */
    this.valueFormGroup$
      .pipe(
        takeUntilDestroyed(),
        switchMap(fg => fg.valueChanges)
      )
      .subscribe(formGroup => {
        const selectedIds = Object.keys(formGroup).filter(key => !!formGroup[key]);

        // filter true values
        this.writeValue(selectedIds);
      });
  }

  trackByValue: TrackByFunction<iItem> = (_, c) => c.label;

  checkAllTrue(checkAllTrue: boolean[]) {
    return !!checkAllTrue.every(v => v === true);
  }

  checkOneTrue(checkAllTrue: boolean[]) {
    return !!checkAllTrue.find(v => v === true);
  }

  markAllChildren($event: MatCheckboxChange, ctrls: checkboxCtrl[]) {
    ctrls.forEach(c => this.markControl($event, c));
  }

  markControl($event: MatCheckboxChange, ctrl: checkboxCtrl) {
    ctrl.setValue($event.checked);
  }

  toValues$(ctrls: checkboxCtrl[]) {
    return combineLatest(ctrls.map(c => c.valueChanges.pipe(startWith(c.value))));
  }

  setDefaultCheckedItems() {
    this.control.setValue(this.control.defaultValue);
    this.resetValues$.next(true);
  }

  toggleShowOnlyChecked() {
    this.showOnlyChecked$.next(!this.showOnlyChecked$.value);
  }

  toggleAll() {
    this.toggleAll$.next(!this.toggleAll$.value);

    Object.values(this.tmpOpenStatus).forEach(status$ => {
      status$.next(this.toggleAll$.value);
    });
  }
}
