export const environment = {
  production: true,
  enableServiceWorker: false,
  apiUrl: 'https://csi-dotnet.cpit.app',
  keycloakConfig: {
    url: 'https://auth.cpit.app/',
    realm: 'cpit',
    clientId: 'cpit-tarifingress-frontend',
  },
};
