import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import type { Position, Snackbar, SnackbarTypes } from '../..';
import { SNACKBAR_DATA, SnackbarService } from '../..';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ui-snackbar',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent {
  snackbarService = inject(SnackbarService);
  data = inject(SNACKBAR_DATA);
  data$ = new BehaviorSubject<Snackbar[]>(this.data);

  backgroundColor: { [key in SnackbarTypes]: string } = {
    info: 'bg-cp-primary-100',
    warning: 'bg-cp-highlight-mid-100',
    error: 'bg-cp-error-100',
    success: 'bg-cp-secondary-100',
  } as const;

  trackById(index: number, item: Snackbar) {
    return item.id;
  }

  trackByPosition(_: number, item: { position: Position; items: Array<Snackbar> }) {
    return item.position;
  }

  updateData(data: Array<Snackbar>) {
    this.data$.next(data);
  }
}
