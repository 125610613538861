/**
 * TarifIngress Frontend v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateFondslisteInput } from '../model/create-fondsliste-input.model';
// @ts-ignore
import { DeleteInfoInput } from '../model/delete-info-input.model';
// @ts-ignore
import { EsgKennzahlTyp } from '../model/esg-kennzahl-typ.model';
// @ts-ignore
import { Fonds } from '../model/fonds.model';
// @ts-ignore
import { FondsStandardFondsInput } from '../model/fonds-standard-fonds-input.model';
// @ts-ignore
import { Fondsliste } from '../model/fondsliste.model';
// @ts-ignore
import { FondslisteFuerDropdownInput } from '../model/fondsliste-fuer-dropdown-input.model';
// @ts-ignore
import { GetEsgKennzahlenInfoInput } from '../model/get-esg-kennzahlen-info-input.model';
// @ts-ignore
import { GetEsgKennzahlenInput } from '../model/get-esg-kennzahlen-input.model';
// @ts-ignore
import { ListFondsIdsRequestDto } from '../model/list-fonds-ids-request-dto.model';
// @ts-ignore
import { ListFondslisteIdsRequestDto } from '../model/list-fondsliste-ids-request-dto.model';
// @ts-ignore
import { ListIdResponse } from '../model/list-id-response.model';
// @ts-ignore
import { ObjectCreatedResponse } from '../model/object-created-response.model';
// @ts-ignore
import { PropertyUpdatedResponse } from '../model/property-updated-response.model';
// @ts-ignore
import { UpdateFondslisteInput } from '../model/update-fondsliste-input.model';
// @ts-ignore
import { UpdateFondslisteMaxanzahlInput } from '../model/update-fondsliste-maxanzahl-input.model';
// @ts-ignore
import { UpdateFondslisteMindestanteilInput } from '../model/update-fondsliste-mindestanteil-input.model';
// @ts-ignore
import { UpdateFondslisteNameInput } from '../model/update-fondsliste-name-input.model';
// @ts-ignore
import { UpsertEsgKennzahlenInput } from '../model/upsert-esg-kennzahlen-input.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    FondslisteServiceInterface,
    CreateFondslisteRequestParams,
    DeleteFondslisteRequestParams,
    DeleteInfoFondslisteRequestParams,
    GetEsgKennzahlenRequestParams,
    GetEsgKennzahlenInfoRequestParams,
    GetFondsManyRequestParams,
    GetFondsStandardFondsRequestParams,
    GetFondslisteRequestParams,
    GetFondslistenRequestParams,
    GetFondslistenFuerDropdownRequestParams,
    ListFondsIdsRequestParams,
    ListFondslisteIdsRequestParams,
    UpdateFondsStandardFondsRequestParams,
    UpdateFondslisteRequestParams,
    UpdateFondslisteMaxanzahlRequestParams,
    UpdateFondslisteMindestanteilRequestParams,
    UpdateFondslisteNameRequestParams,
    UpsertEsgKennzahlenRequestParams
} from './fondsliste.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class FondslisteService implements FondslisteServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFondsliste(requestParameters?: CreateFondslisteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ObjectCreatedResponse>;
    public createFondsliste(requestParameters?: CreateFondslisteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ObjectCreatedResponse>>;
    public createFondsliste(requestParameters?: CreateFondslisteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ObjectCreatedResponse>>;
    public createFondsliste(requestParameters?: CreateFondslisteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const createFondslisteInput = requestParameters?.createFondslisteInput;
        if (createFondslisteInput === null || createFondslisteInput === undefined) {
            throw new Error('Required parameter createFondslisteInput was null or undefined when calling createFondsliste.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/create-fondsliste`;
        return this.httpClient.request<ObjectCreatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createFondslisteInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFondsliste(requestParameters?: DeleteFondslisteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public deleteFondsliste(requestParameters?: DeleteFondslisteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public deleteFondsliste(requestParameters?: DeleteFondslisteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public deleteFondsliste(requestParameters?: DeleteFondslisteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling deleteFondsliste.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling deleteFondsliste.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/delete-fondsliste/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInfoFondsliste(requestParameters?: DeleteInfoFondslisteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DeleteInfoInput>>;
    public deleteInfoFondsliste(requestParameters?: DeleteInfoFondslisteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DeleteInfoInput>>>;
    public deleteInfoFondsliste(requestParameters?: DeleteInfoFondslisteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DeleteInfoInput>>>;
    public deleteInfoFondsliste(requestParameters?: DeleteInfoFondslisteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling deleteInfoFondsliste.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling deleteInfoFondsliste.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/delete-info-fondsliste/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<DeleteInfoInput>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgKennzahlen(requestParameters?: GetEsgKennzahlenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetEsgKennzahlenInput>;
    public getEsgKennzahlen(requestParameters?: GetEsgKennzahlenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetEsgKennzahlenInput>>;
    public getEsgKennzahlen(requestParameters?: GetEsgKennzahlenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetEsgKennzahlenInput>>;
    public getEsgKennzahlen(requestParameters?: GetEsgKennzahlenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const produkt = requestParameters?.produkt;
        if (produkt === null || produkt === undefined) {
            throw new Error('Required parameter produkt was null or undefined when calling getEsgKennzahlen.');
        }
        const typ = requestParameters?.typ;
        if (typ === null || typ === undefined) {
            throw new Error('Required parameter typ was null or undefined when calling getEsgKennzahlen.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (produkt !== undefined && produkt !== null) {
            localVarHeaders = localVarHeaders.set('produkt', String(produkt));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-esg-kennzahlen/typ/${this.configuration.encodeParam({name: "typ", value: typ, in: "path", style: "simple", explode: false, dataType: "EsgKennzahlTyp", dataFormat: undefined})}`;
        return this.httpClient.request<GetEsgKennzahlenInput>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgKennzahlenInfo(requestParameters?: GetEsgKennzahlenInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetEsgKennzahlenInfoInput>;
    public getEsgKennzahlenInfo(requestParameters?: GetEsgKennzahlenInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetEsgKennzahlenInfoInput>>;
    public getEsgKennzahlenInfo(requestParameters?: GetEsgKennzahlenInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetEsgKennzahlenInfoInput>>;
    public getEsgKennzahlenInfo(requestParameters?: GetEsgKennzahlenInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const produkt = requestParameters?.produkt;
        if (produkt === null || produkt === undefined) {
            throw new Error('Required parameter produkt was null or undefined when calling getEsgKennzahlenInfo.');
        }
        const typ = requestParameters?.typ;
        if (typ === null || typ === undefined) {
            throw new Error('Required parameter typ was null or undefined when calling getEsgKennzahlenInfo.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (produkt !== undefined && produkt !== null) {
            localVarHeaders = localVarHeaders.set('produkt', String(produkt));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-esg-kennzahlen-info/typ/${this.configuration.encodeParam({name: "typ", value: typ, in: "path", style: "simple", explode: false, dataType: "EsgKennzahlTyp", dataFormat: undefined})}`;
        return this.httpClient.request<GetEsgKennzahlenInfoInput>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFondsMany(requestParameters?: GetFondsManyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: Fonds; }>;
    public getFondsMany(requestParameters?: GetFondsManyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: Fonds; }>>;
    public getFondsMany(requestParameters?: GetFondsManyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: Fonds; }>>;
    public getFondsMany(requestParameters?: GetFondsManyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const requestBody = requestParameters?.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling getFondsMany.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-fonds-many`;
        return this.httpClient.request<{ [key: string]: Fonds; }>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFondsStandardFonds(requestParameters?: GetFondsStandardFondsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FondsStandardFondsInput>;
    public getFondsStandardFonds(requestParameters?: GetFondsStandardFondsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FondsStandardFondsInput>>;
    public getFondsStandardFonds(requestParameters?: GetFondsStandardFondsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FondsStandardFondsInput>>;
    public getFondsStandardFonds(requestParameters?: GetFondsStandardFondsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling getFondsStandardFonds.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-fonds-standardfonds/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<FondsStandardFondsInput>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFondsliste(requestParameters?: GetFondslisteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Fondsliste>;
    public getFondsliste(requestParameters?: GetFondslisteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Fondsliste>>;
    public getFondsliste(requestParameters?: GetFondslisteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Fondsliste>>;
    public getFondsliste(requestParameters?: GetFondslisteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling getFondsliste.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-fondsliste/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Fondsliste>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFondslisten(requestParameters?: GetFondslistenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: Fondsliste; }>;
    public getFondslisten(requestParameters?: GetFondslistenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: Fondsliste; }>>;
    public getFondslisten(requestParameters?: GetFondslistenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: Fondsliste; }>>;
    public getFondslisten(requestParameters?: GetFondslistenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const requestBody = requestParameters?.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling getFondslisten.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-fondslisten`;
        return this.httpClient.request<{ [key: string]: Fondsliste; }>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFondslistenFuerDropdown(requestParameters?: GetFondslistenFuerDropdownRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<FondslisteFuerDropdownInput>>;
    public getFondslistenFuerDropdown(requestParameters?: GetFondslistenFuerDropdownRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<FondslisteFuerDropdownInput>>>;
    public getFondslistenFuerDropdown(requestParameters?: GetFondslistenFuerDropdownRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<FondslisteFuerDropdownInput>>>;
    public getFondslistenFuerDropdown(requestParameters?: GetFondslistenFuerDropdownRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const versichererId = requestParameters?.versichererId;
        if (versichererId === null || versichererId === undefined) {
            throw new Error('Required parameter versichererId was null or undefined when calling getFondslistenFuerDropdown.');
        }
        const sparten = requestParameters?.sparten;
        if (sparten === null || sparten === undefined) {
            throw new Error('Required parameter sparten was null or undefined when calling getFondslistenFuerDropdown.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sparten) {
            sparten.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'sparten');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/fondslisten/versicherer/${this.configuration.encodeParam({name: "versichererId", value: versichererId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<FondslisteFuerDropdownInput>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listFondsIds(requestParameters?: ListFondsIdsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListIdResponse>;
    public listFondsIds(requestParameters?: ListFondsIdsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListIdResponse>>;
    public listFondsIds(requestParameters?: ListFondsIdsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListIdResponse>>;
    public listFondsIds(requestParameters?: ListFondsIdsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling listFondsIds.');
        }
        const listFondsIdsRequestDto = requestParameters?.listFondsIdsRequestDto;
        if (listFondsIdsRequestDto === null || listFondsIdsRequestDto === undefined) {
            throw new Error('Required parameter listFondsIdsRequestDto was null or undefined when calling listFondsIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fondslisteId !== undefined && fondslisteId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fondslisteId, 'fondslisteId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/list-fonds-ids`;
        return this.httpClient.request<ListIdResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: listFondsIdsRequestDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listFondslisteIds(requestParameters?: ListFondslisteIdsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListIdResponse>;
    public listFondslisteIds(requestParameters?: ListFondslisteIdsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListIdResponse>>;
    public listFondslisteIds(requestParameters?: ListFondslisteIdsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListIdResponse>>;
    public listFondslisteIds(requestParameters?: ListFondslisteIdsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const sparte = requestParameters?.sparte;
        if (sparte === null || sparte === undefined) {
            throw new Error('Required parameter sparte was null or undefined when calling listFondslisteIds.');
        }
        const listFondslisteIdsRequestDto = requestParameters?.listFondslisteIdsRequestDto;
        if (listFondslisteIdsRequestDto === null || listFondslisteIdsRequestDto === undefined) {
            throw new Error('Required parameter listFondslisteIdsRequestDto was null or undefined when calling listFondslisteIds.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (sparte !== undefined && sparte !== null) {
            localVarHeaders = localVarHeaders.set('sparte', String(sparte));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/list-fondsliste-ids`;
        return this.httpClient.request<ListIdResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: listFondslisteIdsRequestDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFondsStandardFonds(requestParameters?: UpdateFondsStandardFondsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateFondsStandardFonds(requestParameters?: UpdateFondsStandardFondsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateFondsStandardFonds(requestParameters?: UpdateFondsStandardFondsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateFondsStandardFonds(requestParameters?: UpdateFondsStandardFondsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling updateFondsStandardFonds.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateFondsStandardFonds.');
        }
        const fondsStandardFondsInput = requestParameters?.fondsStandardFondsInput;
        if (fondsStandardFondsInput === null || fondsStandardFondsInput === undefined) {
            throw new Error('Required parameter fondsStandardFondsInput was null or undefined when calling updateFondsStandardFonds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-fonds-standard-fonds/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: fondsStandardFondsInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFondsliste(requestParameters?: UpdateFondslisteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateFondsliste(requestParameters?: UpdateFondslisteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateFondsliste(requestParameters?: UpdateFondslisteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateFondsliste(requestParameters?: UpdateFondslisteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling updateFondsliste.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateFondsliste.');
        }
        const updateFondslisteInput = requestParameters?.updateFondslisteInput;
        if (updateFondslisteInput === null || updateFondslisteInput === undefined) {
            throw new Error('Required parameter updateFondslisteInput was null or undefined when calling updateFondsliste.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-fondsliste/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateFondslisteInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFondslisteMaxanzahl(requestParameters?: UpdateFondslisteMaxanzahlRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateFondslisteMaxanzahl(requestParameters?: UpdateFondslisteMaxanzahlRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateFondslisteMaxanzahl(requestParameters?: UpdateFondslisteMaxanzahlRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateFondslisteMaxanzahl(requestParameters?: UpdateFondslisteMaxanzahlRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling updateFondslisteMaxanzahl.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateFondslisteMaxanzahl.');
        }
        const updateFondslisteMaxanzahlInput = requestParameters?.updateFondslisteMaxanzahlInput;
        if (updateFondslisteMaxanzahlInput === null || updateFondslisteMaxanzahlInput === undefined) {
            throw new Error('Required parameter updateFondslisteMaxanzahlInput was null or undefined when calling updateFondslisteMaxanzahl.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-fondsliste-maxanzahl/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateFondslisteMaxanzahlInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFondslisteMindestanteil(requestParameters?: UpdateFondslisteMindestanteilRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateFondslisteMindestanteil(requestParameters?: UpdateFondslisteMindestanteilRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateFondslisteMindestanteil(requestParameters?: UpdateFondslisteMindestanteilRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateFondslisteMindestanteil(requestParameters?: UpdateFondslisteMindestanteilRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling updateFondslisteMindestanteil.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateFondslisteMindestanteil.');
        }
        const updateFondslisteMindestanteilInput = requestParameters?.updateFondslisteMindestanteilInput;
        if (updateFondslisteMindestanteilInput === null || updateFondslisteMindestanteilInput === undefined) {
            throw new Error('Required parameter updateFondslisteMindestanteilInput was null or undefined when calling updateFondslisteMindestanteil.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-fondsliste-mindestanteil/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateFondslisteMindestanteilInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFondslisteName(requestParameters?: UpdateFondslisteNameRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateFondslisteName(requestParameters?: UpdateFondslisteNameRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateFondslisteName(requestParameters?: UpdateFondslisteNameRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateFondslisteName(requestParameters?: UpdateFondslisteNameRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const fondslisteId = requestParameters?.fondslisteId;
        if (fondslisteId === null || fondslisteId === undefined) {
            throw new Error('Required parameter fondslisteId was null or undefined when calling updateFondslisteName.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateFondslisteName.');
        }
        const updateFondslisteNameInput = requestParameters?.updateFondslisteNameInput;
        if (updateFondslisteNameInput === null || updateFondslisteNameInput === undefined) {
            throw new Error('Required parameter updateFondslisteNameInput was null or undefined when calling updateFondslisteName.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-fondsliste-name/${this.configuration.encodeParam({name: "fondslisteId", value: fondslisteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateFondslisteNameInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-fondsliste
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upsertEsgKennzahlen(requestParameters?: UpsertEsgKennzahlenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public upsertEsgKennzahlen(requestParameters?: UpsertEsgKennzahlenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public upsertEsgKennzahlen(requestParameters?: UpsertEsgKennzahlenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public upsertEsgKennzahlen(requestParameters?: UpsertEsgKennzahlenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const produkt = requestParameters?.produkt;
        if (produkt === null || produkt === undefined) {
            throw new Error('Required parameter produkt was null or undefined when calling upsertEsgKennzahlen.');
        }
        const upsertEsgKennzahlenInput = requestParameters?.upsertEsgKennzahlenInput;
        if (upsertEsgKennzahlenInput === null || upsertEsgKennzahlenInput === undefined) {
            throw new Error('Required parameter upsertEsgKennzahlenInput was null or undefined when calling upsertEsgKennzahlen.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (produkt !== undefined && produkt !== null) {
            localVarHeaders = localVarHeaders.set('produkt', String(produkt));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/upsert-esg-kennzahlen`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: upsertEsgKennzahlenInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
