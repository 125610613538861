import type { DoCheck, OnInit } from '@angular/core';
import { DestroyRef, Directive, Injector, Input, inject } from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import {
  FormBuilder,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { objectEquals } from '../misc/share';

@Directive({
  selector: '[uiControlValueAccessor]',
  standalone: true,
})
export class ControlValueAccessorDirective<T> implements ControlValueAccessor, OnInit, DoCheck {
  @Input() defaultValue?: T;

  onTouched: () => void = () => void 0;
  onChange: (value: T) => void = () => void 0;

  injector = inject(Injector);
  destroyRef = inject(DestroyRef);

  control!: FormControl<T>;
  disabled$ = new BehaviorSubject<boolean>(false);

  @Input() set disabled(state: boolean) {
    this.setDisabledState(!!state);
  }

  ngDoCheck() {
    this.setFormControlOnInit();
  }

  ngOnInit(): void {
    this.setFormControlOnInit();
  }

  /**
   * Set this method only on init!
   */
  private _getControl(): FormControl<T> {
    try {
      const formControl = this.injector.get(NgControl);
      if (formControl instanceof FormControlName) {
        // !Do not remove this code...
        // const grouname = this.injector.get(FormGroupName);
        // if (grouname) {
        //   return this.injector
        //     .get(FormGroupDirective)
        //     .getFormGroup(grouname)
        //     .get(formControl.name as string) as FormControl<T>;
        // }
        return this.injector.get(FormGroupDirective).getControl(formControl);
      } else if (formControl instanceof NgModel) {
        return this.injector.get(NgModel).control;
      } else {
        return formControl.control as FormControl;
      }
    } catch (err) {
      if (this.control) {
        // recycing the last in-component ctrl because we dont have to create an new one
        return this.control;
      }
      return new FormBuilder().nonNullable.control(this.defaultValue as unknown as T);
    }
  }

  /**
   * Set this method only on init!
   */
  setFormControlOnInit() {
    this.control = this._getControl();
  }

  private _isIdentical = (v1: any, v2: any) => {
    return objectEquals(JSON.parse(JSON.stringify(v1)), JSON.parse(JSON.stringify(v2)));
  };

  valueChanged = (value: T) => {
    return !this._isIdentical(value, this.control.value);
  };

  isDefaultValue = (v = this.control.value) => {
    return this._isIdentical(v, this.control.defaultValue);
  };

  writeValue(value: T): void {
    /**
     * Prevent circular value set.. e.g Select all on ui-select
     */
    if (this.valueChanged(value)) {
      if (this.control) {
        this.control.setValue(value);
      } else {
        this.control = new FormBuilder().nonNullable.control<T>(value);
      }
    }
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }
}
