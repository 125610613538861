<ng-template #errorsTemplate let-errors="errors">
  @if (errors) {
    @if (errors.pattern) {
      @if (regexMessage) {
        <span [innerHTML]="regexMessage"></span>
      } @else {
        <ng-container> Die Eingabe enthält ein unzulässiges Zeichen. </ng-container>
      }
    }
    @if (errors.required) {
      Bitte ergänzen Sie das Feld.
    }
    @if (errors.minlength) {
      Bitte ergänzen Sie dieses Feld mit einer Mindestlänge von
      {{ errors.minlength.requiredLength }} Zeichen.
    }
    @if (errors.maxlength) {
      Das Feld {{ fieldName }} besitzt eine maximale Länge von
      {{ errors.maxlength.requiredLength }} Zeichen.
    }
    @if (errors.min) {
      Das Feld {{ fieldName }} erfordert ein min. Wert von {{ errors.min.min }}.
    }
    @if (errors.max) {
      Das Feld {{ fieldName }} hat den maximalen Wert von {{ errors.max.max }} überschritten.
    }
    @if (errors.number) {
      Das Feld {{ fieldName }} sollte eine Zahl sein.
    }
    @if (errors.minDate) {
      Das Datum muss am oder nach dem
      {{ errors.minDate?.value | date }} liegen.
    }
    @if (errors.maxDate) {
      Das Datum muss am oder vor dem
      {{ errors.maxDate?.value | date }} liegen.
    }
    @if (errors.matDatepickerFilter) {
      Das ausgewählte Datum ist nicht zulässig. Bitte wählen Sie ein anderes Datum.
    }
    @if (errors.date) {
      Das Feld {{ fieldName }} sollte ein Datum sein (TT.MM.JJJJ).
    }
    @if (errors.uniqueQuantity) {
      Es gibt bereits eine Anzahloption mit der gleichen Menge.
    }
    @if (errors.invalidPostalCode) {
      Bitte gib eine gültige Postleitzahl ein. Sie muss genau 5 Ziffern enthalten.
    }
    @if (errors.notMatch) {
      Die Eingabe stimmt nicht überein.
    }
    @if (errors.custom) {
      {{ errors.custom }}
    }
  }
</ng-template>

<ng-container
  *ngTemplateOutlet="errorsTemplate; context: { errors: control.errors }"
/>
