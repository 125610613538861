/**
 * TarifIngress Frontend v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateTarifattributInput } from '../model/create-tarifattribut-input.model';
// @ts-ignore
import { DeleteInfoInput } from '../model/delete-info-input.model';
// @ts-ignore
import { ListIdResponse } from '../model/list-id-response.model';
// @ts-ignore
import { ListTarifattributIdsColumn } from '../model/list-tarifattribut-ids-column.model';
// @ts-ignore
import { ListTarifattributIdsRequestDto } from '../model/list-tarifattribut-ids-request-dto.model';
// @ts-ignore
import { ObjectCreatedResponse } from '../model/object-created-response.model';
// @ts-ignore
import { PropertyUpdatedResponse } from '../model/property-updated-response.model';
// @ts-ignore
import { SortOrderDirection } from '../model/sort-order-direction.model';
// @ts-ignore
import { Tarifattribut } from '../model/tarifattribut.model';
// @ts-ignore
import { UpdateTarifattributBearbeitungsinfoInput } from '../model/update-tarifattribut-bearbeitungsinfo-input.model';
// @ts-ignore
import { UpdateTarifattributBedingungInput } from '../model/update-tarifattribut-bedingung-input.model';
// @ts-ignore
import { UpdateTarifattributDifficultyInput } from '../model/update-tarifattribut-difficulty-input.model';
// @ts-ignore
import { UpdateTarifattributHierarchyInput } from '../model/update-tarifattribut-hierarchy-input.model';
// @ts-ignore
import { UpdateTarifattributInfotextInput } from '../model/update-tarifattribut-infotext-input.model';
// @ts-ignore
import { UpdateTarifattributInput } from '../model/update-tarifattribut-input.model';
// @ts-ignore
import { UpdateTarifattributLeistungskriteriumInput } from '../model/update-tarifattribut-leistungskriterium-input.model';
// @ts-ignore
import { UpdateTarifattributNameInput } from '../model/update-tarifattribut-name-input.model';
// @ts-ignore
import { UpdateTarifattributTarifbereicheInput } from '../model/update-tarifattribut-tarifbereiche-input.model';
// @ts-ignore
import { UpdateTarifattributTypeDefaultValueInput } from '../model/update-tarifattribut-type-default-value-input.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    TarifattributServiceInterface,
    CreateTarifattributRequestParams,
    DeleteInfoTarifattributRequestParams,
    DeleteTarifattributRequestParams,
    GetTarifattributRequestParams,
    GetTarifattributeRequestParams,
    ListTarifattributIdsRequestParams,
    ListTarifattributIdsOldRequestParams,
    UpdateTarifattributRequestParams,
    UpdateTarifattributBearbeitungsinfoRequestParams,
    UpdateTarifattributBedingungRequestParams,
    UpdateTarifattributDifficultyRequestParams,
    UpdateTarifattributHierarchyRequestParams,
    UpdateTarifattributInfotextRequestParams,
    UpdateTarifattributLeistungskriteriumRequestParams,
    UpdateTarifattributNameRequestParams,
    UpdateTarifattributTarifbereicheRequestParams,
    UpdateTarifattributTypeDefaultValueRequestParams
} from './tarifattribut.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class TarifattributService implements TarifattributServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut, cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTarifattribut(requestParameters?: CreateTarifattributRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ObjectCreatedResponse>;
    public createTarifattribut(requestParameters?: CreateTarifattributRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ObjectCreatedResponse>>;
    public createTarifattribut(requestParameters?: CreateTarifattributRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ObjectCreatedResponse>>;
    public createTarifattribut(requestParameters?: CreateTarifattributRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const createTarifattributInput = requestParameters?.createTarifattributInput;
        if (createTarifattributInput === null || createTarifattributInput === undefined) {
            throw new Error('Required parameter createTarifattributInput was null or undefined when calling createTarifattribut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/create-tarifattribut`;
        return this.httpClient.request<ObjectCreatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createTarifattributInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInfoTarifattribut(requestParameters?: DeleteInfoTarifattributRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DeleteInfoInput>>;
    public deleteInfoTarifattribut(requestParameters?: DeleteInfoTarifattributRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DeleteInfoInput>>>;
    public deleteInfoTarifattribut(requestParameters?: DeleteInfoTarifattributRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DeleteInfoInput>>>;
    public deleteInfoTarifattribut(requestParameters?: DeleteInfoTarifattributRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling deleteInfoTarifattribut.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling deleteInfoTarifattribut.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/delete-info-tarifattribute/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<DeleteInfoInput>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTarifattribut(requestParameters?: DeleteTarifattributRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public deleteTarifattribut(requestParameters?: DeleteTarifattributRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public deleteTarifattribut(requestParameters?: DeleteTarifattributRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public deleteTarifattribut(requestParameters?: DeleteTarifattributRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling deleteTarifattribut.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling deleteTarifattribut.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/delete-tarifattribute/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTarifattribut(requestParameters?: GetTarifattributRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Tarifattribut>;
    public getTarifattribut(requestParameters?: GetTarifattributRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Tarifattribut>>;
    public getTarifattribut(requestParameters?: GetTarifattributRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Tarifattribut>>;
    public getTarifattribut(requestParameters?: GetTarifattributRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling getTarifattribut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-tarifattribut/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Tarifattribut>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTarifattribute(requestParameters?: GetTarifattributeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: Tarifattribut; }>;
    public getTarifattribute(requestParameters?: GetTarifattributeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: Tarifattribut; }>>;
    public getTarifattribute(requestParameters?: GetTarifattributeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: Tarifattribut; }>>;
    public getTarifattribute(requestParameters?: GetTarifattributeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const requestBody = requestParameters?.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling getTarifattribute.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-tarifattribute`;
        return this.httpClient.request<{ [key: string]: Tarifattribut; }>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTarifattributIds(requestParameters?: ListTarifattributIdsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListIdResponse>;
    public listTarifattributIds(requestParameters?: ListTarifattributIdsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListIdResponse>>;
    public listTarifattributIds(requestParameters?: ListTarifattributIdsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListIdResponse>>;
    public listTarifattributIds(requestParameters?: ListTarifattributIdsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const produkt = requestParameters?.produkt;
        if (produkt === null || produkt === undefined) {
            throw new Error('Required parameter produkt was null or undefined when calling listTarifattributIds.');
        }
        const listTarifattributIdsRequestDto = requestParameters?.listTarifattributIdsRequestDto;
        if (listTarifattributIdsRequestDto === null || listTarifattributIdsRequestDto === undefined) {
            throw new Error('Required parameter listTarifattributIdsRequestDto was null or undefined when calling listTarifattributIds.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (produkt !== undefined && produkt !== null) {
            localVarHeaders = localVarHeaders.set('produkt', String(produkt));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/list-tarifattribut-ids`;
        return this.httpClient.request<ListIdResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: listTarifattributIdsRequestDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * !!! OBSOLETE: Since 2024-11-25, use POST instead !!!  Requires all of the following roles: cpit-tarifingress-read-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public listTarifattributIdsOld(requestParameters?: ListTarifattributIdsOldRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ListIdResponse>;
    public listTarifattributIdsOld(requestParameters?: ListTarifattributIdsOldRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ListIdResponse>>;
    public listTarifattributIdsOld(requestParameters?: ListTarifattributIdsOldRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ListIdResponse>>;
    public listTarifattributIdsOld(requestParameters?: ListTarifattributIdsOldRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const produkt = requestParameters?.produkt;
        if (produkt === null || produkt === undefined) {
            throw new Error('Required parameter produkt was null or undefined when calling listTarifattributIdsOld.');
        }
        const filterColumn = requestParameters?.filterColumn;
        const filterValue = requestParameters?.filterValue;
        const orderDirection = requestParameters?.orderDirection;
        const orderBy = requestParameters?.orderBy;
        const limit = requestParameters?.limit;
        const offset = requestParameters?.offset;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filterColumn !== undefined && filterColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterColumn, 'filterColumn');
        }
        if (filterValue !== undefined && filterValue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterValue, 'filterValue');
        }
        if (orderDirection !== undefined && orderDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDirection, 'orderDirection');
        }
        if (orderBy !== undefined && orderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBy, 'orderBy');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }

        let localVarHeaders = this.defaultHeaders;
        if (produkt !== undefined && produkt !== null) {
            localVarHeaders = localVarHeaders.set('produkt', String(produkt));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/list-tarifattribut-ids`;
        return this.httpClient.request<ListIdResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattribut(requestParameters?: UpdateTarifattributRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattribut(requestParameters?: UpdateTarifattributRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattribut(requestParameters?: UpdateTarifattributRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattribut(requestParameters?: UpdateTarifattributRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattribut.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattribut.');
        }
        const updateTarifattributInput = requestParameters?.updateTarifattributInput;
        if (updateTarifattributInput === null || updateTarifattributInput === undefined) {
            throw new Error('Required parameter updateTarifattributInput was null or undefined when calling updateTarifattribut.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributBearbeitungsinfo(requestParameters?: UpdateTarifattributBearbeitungsinfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributBearbeitungsinfo(requestParameters?: UpdateTarifattributBearbeitungsinfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributBearbeitungsinfo(requestParameters?: UpdateTarifattributBearbeitungsinfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributBearbeitungsinfo(requestParameters?: UpdateTarifattributBearbeitungsinfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributBearbeitungsinfo.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributBearbeitungsinfo.');
        }
        const updateTarifattributBearbeitungsinfoInput = requestParameters?.updateTarifattributBearbeitungsinfoInput;
        if (updateTarifattributBearbeitungsinfoInput === null || updateTarifattributBearbeitungsinfoInput === undefined) {
            throw new Error('Required parameter updateTarifattributBearbeitungsinfoInput was null or undefined when calling updateTarifattributBearbeitungsinfo.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-bearbeitungsinfo/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributBearbeitungsinfoInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributBedingung(requestParameters?: UpdateTarifattributBedingungRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributBedingung(requestParameters?: UpdateTarifattributBedingungRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributBedingung(requestParameters?: UpdateTarifattributBedingungRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributBedingung(requestParameters?: UpdateTarifattributBedingungRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributBedingung.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributBedingung.');
        }
        const updateTarifattributBedingungInput = requestParameters?.updateTarifattributBedingungInput;
        if (updateTarifattributBedingungInput === null || updateTarifattributBedingungInput === undefined) {
            throw new Error('Required parameter updateTarifattributBedingungInput was null or undefined when calling updateTarifattributBedingung.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-bedingung/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributBedingungInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributDifficulty(requestParameters?: UpdateTarifattributDifficultyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributDifficulty(requestParameters?: UpdateTarifattributDifficultyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributDifficulty(requestParameters?: UpdateTarifattributDifficultyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributDifficulty(requestParameters?: UpdateTarifattributDifficultyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributDifficulty.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributDifficulty.');
        }
        const updateTarifattributDifficultyInput = requestParameters?.updateTarifattributDifficultyInput;
        if (updateTarifattributDifficultyInput === null || updateTarifattributDifficultyInput === undefined) {
            throw new Error('Required parameter updateTarifattributDifficultyInput was null or undefined when calling updateTarifattributDifficulty.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-difficulty/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributDifficultyInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributHierarchy(requestParameters?: UpdateTarifattributHierarchyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributHierarchy(requestParameters?: UpdateTarifattributHierarchyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributHierarchy(requestParameters?: UpdateTarifattributHierarchyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributHierarchy(requestParameters?: UpdateTarifattributHierarchyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributHierarchy.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributHierarchy.');
        }
        const updateTarifattributHierarchyInput = requestParameters?.updateTarifattributHierarchyInput;
        if (updateTarifattributHierarchyInput === null || updateTarifattributHierarchyInput === undefined) {
            throw new Error('Required parameter updateTarifattributHierarchyInput was null or undefined when calling updateTarifattributHierarchy.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-hierarchy/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributHierarchyInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributInfotext(requestParameters?: UpdateTarifattributInfotextRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributInfotext(requestParameters?: UpdateTarifattributInfotextRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributInfotext(requestParameters?: UpdateTarifattributInfotextRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributInfotext(requestParameters?: UpdateTarifattributInfotextRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributInfotext.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributInfotext.');
        }
        const updateTarifattributInfotextInput = requestParameters?.updateTarifattributInfotextInput;
        if (updateTarifattributInfotextInput === null || updateTarifattributInfotextInput === undefined) {
            throw new Error('Required parameter updateTarifattributInfotextInput was null or undefined when calling updateTarifattributInfotext.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-infotext/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributInfotextInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributLeistungskriterium(requestParameters?: UpdateTarifattributLeistungskriteriumRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributLeistungskriterium(requestParameters?: UpdateTarifattributLeistungskriteriumRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributLeistungskriterium(requestParameters?: UpdateTarifattributLeistungskriteriumRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributLeistungskriterium(requestParameters?: UpdateTarifattributLeistungskriteriumRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributLeistungskriterium.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributLeistungskriterium.');
        }
        const updateTarifattributLeistungskriteriumInput = requestParameters?.updateTarifattributLeistungskriteriumInput;
        if (updateTarifattributLeistungskriteriumInput === null || updateTarifattributLeistungskriteriumInput === undefined) {
            throw new Error('Required parameter updateTarifattributLeistungskriteriumInput was null or undefined when calling updateTarifattributLeistungskriterium.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-leistungskriterium/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributLeistungskriteriumInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributName(requestParameters?: UpdateTarifattributNameRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributName(requestParameters?: UpdateTarifattributNameRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributName(requestParameters?: UpdateTarifattributNameRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributName(requestParameters?: UpdateTarifattributNameRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributName.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributName.');
        }
        const updateTarifattributNameInput = requestParameters?.updateTarifattributNameInput;
        if (updateTarifattributNameInput === null || updateTarifattributNameInput === undefined) {
            throw new Error('Required parameter updateTarifattributNameInput was null or undefined when calling updateTarifattributName.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-name/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributNameInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut, cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributTarifbereiche(requestParameters?: UpdateTarifattributTarifbereicheRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributTarifbereiche(requestParameters?: UpdateTarifattributTarifbereicheRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributTarifbereiche(requestParameters?: UpdateTarifattributTarifbereicheRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributTarifbereiche(requestParameters?: UpdateTarifattributTarifbereicheRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributTarifbereiche.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributTarifbereiche.');
        }
        const updateTarifattributTarifbereicheInput = requestParameters?.updateTarifattributTarifbereicheInput;
        if (updateTarifattributTarifbereicheInput === null || updateTarifattributTarifbereicheInput === undefined) {
            throw new Error('Required parameter updateTarifattributTarifbereicheInput was null or undefined when calling updateTarifattributTarifbereiche.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-tarifbereiche/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributTarifbereicheInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarifattribut
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifattributTypeDefaultValue(requestParameters?: UpdateTarifattributTypeDefaultValueRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PropertyUpdatedResponse>;
    public updateTarifattributTypeDefaultValue(requestParameters?: UpdateTarifattributTypeDefaultValueRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifattributTypeDefaultValue(requestParameters?: UpdateTarifattributTypeDefaultValueRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifattributTypeDefaultValue(requestParameters?: UpdateTarifattributTypeDefaultValueRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const tarifattributId = requestParameters?.tarifattributId;
        if (tarifattributId === null || tarifattributId === undefined) {
            throw new Error('Required parameter tarifattributId was null or undefined when calling updateTarifattributTypeDefaultValue.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifattributTypeDefaultValue.');
        }
        const updateTarifattributTypeDefaultValueInput = requestParameters?.updateTarifattributTypeDefaultValueInput;
        if (updateTarifattributTypeDefaultValueInput === null || updateTarifattributTypeDefaultValueInput === undefined) {
            throw new Error('Required parameter updateTarifattributTypeDefaultValueInput was null or undefined when calling updateTarifattributTypeDefaultValue.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarifattribut-type-default-value/${this.configuration.encodeParam({name: "tarifattributId", value: tarifattributId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifattributTypeDefaultValueInput,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
